import React, { useState } from 'react';
import * as styles from './GeneralForm.module.scss';
import Fade from 'react-reveal/Fade';

const ContactForm = ({ message, handleSubmit, state }) => {
    const [values, setValues] = useState({
        name: '',
        email: '',
        message: message
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    return (
        <div className={styles.generalFormMainContainer}>
            <form onSubmit={handleSubmit}>
                <div
                    className={` ${styles.contactFormDivider2} ${styles.divider2}  `}
                >
                    <Fade bottom distance="50px" delay={100}>
                        <div className={styles.formInput}>
                            <label htmlFor="name" className={styles.formLabel}>
                                Name
                            </label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                className={styles.inputItem}
                                placeholder="Enter your name"
                                onChange={handleChange}
                                value={values.name}
                            />
                        </div>
                    </Fade>

                    <Fade bottom distance="50px" delay={150}>
                        <div className={styles.formInput}>
                            <label htmlFor="email" className={styles.formLabel}>
                                Email Address
                            </label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                className={styles.inputItem}
                                placeholder="Enter your email address"
                                value={values.email}
                                onChange={handleChange}
                            />
                        </div>
                    </Fade>
                </div>

                <Fade bottom distance="50px" delay={200}>
                    <div className={styles.formInput}>
                        <label htmlFor="message" className={styles.formLabel}>
                            Message
                        </label>
                        <textarea
                            type="text"
                            name="message"
                            id="message"
                            className={styles.textInput}
                            placeholder="Enter your message"
                            value={values.message}
                            onChange={handleChange}
                        />
                    </div>
                </Fade>

                <Fade bottom distance="50px" delay={250}>
                    <button
                        type="submit"
                        className={styles.contactFormButton}
                        disabled={state.submitting}
                    >
                        Submit
                    </button>
                </Fade>
            </form>
        </div>
    );
};

export default ContactForm;
