// extracted by mini-css-extract-plugin
export var generalFormMainContainer = "GeneralForm-module--generalFormMainContainer--1RwgJ";
export var formInput = "GeneralForm-module--formInput--2OVds";
export var formInputHalf = "GeneralForm-module--formInputHalf--2MUrp";
export var formLabel = "GeneralForm-module--formLabel--3qj5V";
export var inputItem = "GeneralForm-module--inputItem--30CLs";
export var textInput = "GeneralForm-module--textInput--skaU6";
export var fileUploadContainer = "GeneralForm-module--fileUploadContainer--3RK-l";
export var fileChosen = "GeneralForm-module--fileChosen--1dQLw";
export var fileInput = "GeneralForm-module--fileInput--22IBN";
export var formInputSelect = "GeneralForm-module--formInputSelect--1Sa4S";
export var formInputSelectHalf = "GeneralForm-module--formInputSelectHalf--149Wh";
export var arrow = "GeneralForm-module--arrow--3qgki";
export var arrowDisabled = "GeneralForm-module--arrowDisabled--3zcF8";
export var background = "GeneralForm-module--background--2PFKk";
export var selection = "GeneralForm-module--selection--OH-hN";
export var disabledSelection = "GeneralForm-module--disabledSelection--3PZ_Z";
export var notSelectedYet = "GeneralForm-module--notSelectedYet--3d_cV";
export var message = "GeneralForm-module--message--5nDo2";
export var formInputCheckbox = "GeneralForm-module--formInputCheckbox--VGHKb";
export var divider3 = "GeneralForm-module--divider3--2NgX7";
export var divider2 = "GeneralForm-module--divider2--2JDC_";
export var methodContainer = "GeneralForm-module--methodContainer--3n_fQ";
export var methodItem = "GeneralForm-module--methodItem--3u-OZ";
export var methodItemChosen = "GeneralForm-module--methodItemChosen--2kZ60";
export var icon = "GeneralForm-module--icon--1LYDp";
export var amountContainer = "GeneralForm-module--amountContainer--1lrGa";
export var amountOuterBox = "GeneralForm-module--amountOuterBox--2_IC2";
export var amountInput = "GeneralForm-module--amountInput--2NNWm";
export var amountBox = "GeneralForm-module--amountBox--1AkIr";
export var active = "GeneralForm-module--active--31Obq";
export var donationFormMonthlyCheckbox = "GeneralForm-module--donationFormMonthlyCheckbox--3W4b_";
export var contactFormButton = "GeneralForm-module--contactFormButton--2L1sB";
export var applicationInfoTitle = "GeneralForm-module--applicationInfoTitle--2Q3gO";
export var applicationProgrammeTitle = "GeneralForm-module--applicationProgrammeTitle--18FW7";
export var buttonsWithLoading = "GeneralForm-module--buttonsWithLoading--3nDyr";
export var applicationFormButton = "GeneralForm-module--applicationFormButton--1gy_u";
export var applicationLastFormInput = "GeneralForm-module--applicationLastFormInput--1HtL_";
export var contactFormDivider2 = "GeneralForm-module--contactFormDivider2--2WmT7";
export var fileName = "GeneralForm-module--fileName--2H6h8";