import React from 'react';
import Layout from '../components/Layout';
import * as styles from './contact.module.scss';
import ContactForm from '../components/ContactForm';
import { FiPhone } from '@react-icons/all-files/fi/FiPhone';
import { AiOutlineMail } from '@react-icons/all-files/ai/AiOutlineMail';
import { FiMapPin } from '@react-icons/all-files/fi/FiMapPin';
import Fade from 'react-reveal/Fade';
import { useForm } from '@formspree/react';
import ThankYou from '../components/ThankYou';

const Contact = ({ location }) => {
    const message =
        typeof location.state === 'undefined' || location.state === null
            ? ''
            : location.state.message;
    const [state, handleSubmit] = useForm(process.env.GATSBY_CONTACT_FORM);

    if (state.succeeded) {
        return (
            <Layout darkBg={false}>
                <ThankYou type="contact" />
            </Layout>
        );
    }

    return (
        <Layout darkBg={false}>
            <div className={styles.contactContainer}>
                <Fade bottom distance="50px">
                    <h5>Contact Us</h5>
                </Fade>

                <div className={styles.innerContainer}>
                    <div className={styles.contactInfo}>
                        <Fade bottom distance="50px" delay={100}>
                            <h6>Contact Information</h6>
                        </Fade>

                        <Fade bottom distance="50px" delay={200}>
                            <p>
                                Want to get in touch? We'd love to hear from
                                you. Here's how you can reach us:
                            </p>
                        </Fade>

                        <div className={styles.items}>
                            <Fade bottom distance="50px" delay={300}>
                                <div className={styles.item}>
                                    <div className={styles.imageContainer}>
                                        <FiPhone className={styles.image} />
                                    </div>

                                    <span>+6012-6710102</span>
                                </div>
                            </Fade>

                            <Fade bottom distance="50px" delay={350}>
                                <div className={styles.item}>
                                    <div className={styles.imageContainer}>
                                        <AiOutlineMail
                                            className={styles.image}
                                        />
                                    </div>

                                    <span>shawnyip@digitalsocietyfund.org</span>
                                </div>
                            </Fade>

                            <Fade bottom distance="50px" delay={400}>
                                <div className={styles.item}>
                                    <div className={styles.imageContainer}>
                                        <FiMapPin className={styles.image} />
                                    </div>

                                    <span>
                                        19, Jalan USJ Heights 1/1B,
                                        <br />
                                        USJ Heights Avenue 47500, <br />
                                        Subang Jaya, Selangor, <br />
                                        Malaysia.
                                    </span>
                                </div>
                            </Fade>
                        </div>
                    </div>

                    <div className={styles.message}>
                        <ContactForm
                            message={
                                message !== '' || message !== null
                                    ? message
                                    : ''
                            }
                            handleSubmit={handleSubmit}
                            state={state}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Contact;
